import React, { useState, useContext } from "react";
import { useGoogleLogin } from "react-google-login";
import { UserContext } from "../context/UserContext";
import { post } from "../apiRequests";
import { Config } from "../Config";
import { Button, ButtonContent, Message } from "semantic-ui-react";
import { setApiErrors } from "lib/utils";
import { trackEvent } from "lib/analytics";

export const EmailConnect = ({ children, ...props }) => {
  const client_id = Config.google_client_id;
  const [user, setUser] = useContext(UserContext);
  
  const NO_ERROR_STATE = { err_code: "", err_message: "" };  
  const [errors, setErrors] = useState(NO_ERROR_STATE);


  const handleLogin = async (googleData) => {
    if(user?.user_id){
    const res = await post(
      "/user/social/google",
      { user_id: user.user_id, token: googleData.tokenId },
      {}
    ).catch((err) => {
      console.log(err);
      trackEvent('Email Connect Failed', err);
      setApiErrors(err, setErrors);
      return;
    });
    if (res && res.data) {
      console.log(res.data);
      trackEvent('Email Connect Success');

        setUser({ ...user, email_verified: res.data.email_verified, email: res.data.email });
    }
  }
    // const data = await res.json()
    // store returned user somehow
  };

  const renderErrors = () => {
    return <Message error content={errors["err_message"]} />;
  };

  const handleFailure = (googleData) => {
    console.log(user);
    console.log(googleData);
  };

  const { signIn, loaded } = useGoogleLogin({
    clientId: client_id,
    onSuccess: handleLogin,
    onFailure: handleFailure,
    cookiePolicy: "single_host_origin",
  });

  const handleSigning = () => {
    trackEvent('Email Connect Clicked')
    signIn();
  }
  // const renderGoogleButton = () => {
  //   if (loggedIn) return "Welcome Back!";
  //   else return <></>;
  // };

  return (
    <>
            {loaded &&
        <Button className="button ui main large gmail" onClick={handleSigning}>
          <ButtonContent><span style={{display:"flex"}}>Log-in with Gmail </span></ButtonContent>
          </Button>
      }
      {errors.err_message && renderErrors()}
    </>
  );
};
