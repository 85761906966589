import { identifyUser } from 'lib/analytics';
import { setUserDetails } from 'lib/utils';
import React, {useState, useEffect} from 'react';
import { useCookies } from "react-cookie";
import {post} from "../apiRequests";

// const initialUserState = {
//     user_id:null,
//     phone:"",
//     phone_verified: false,
//     email:"",
//     email_verified: false,
//     like_tweet: false,
//     wallet_connected: false,
//     wallet_address: "",
//     current_step: 1
// }

export const UserContext = React.createContext({});

export const UserContextProvider = ({children}) => {
    
    // const [cookies, setCookie] = useCookies(["user"]);
    // try{
    // const user_cookie = cookies['user'];
    
    const initialUserState = {
        user_id:null,
        phone:"",
        phone_verified: false,
        email:"",
        email_verified: false,
        twitter_username: null,
        retweet_id: null,
        wallet_connected: false,
        wallet_address: "",
        wallet_name: "",
        current_step: 1,
        challenge_complete: false,
        sign: null,
        sign_url: null,
        twitter_clicked:false,
        sign_status: null

      };
      const [context, setContext] = useState(initialUserState)
    
    useEffect(() => {
        // localStorage.setItem('user', JSON.stringify(context));
        
        // setCookie("user", context, {
        //     path: "/",
        //     maxAge:60 * 30,
        //     // sameSite: true
        //   });
        return () => {
        }
    }, [context]);
    
    useEffect(()=>{
      refreshContext();
    },[]);

    const refreshContext = () => {
      const jwtToken = localStorage.getItem('token');

      if(jwtToken){
        post('/user/social/me',{},{viaOauth: true}).then((res)=>{
          let user_profile = res?.data?.user || null;
          let user_details = setUserDetails(user_profile);
          setContext({
            ...context,
            ...user_details
          });
          if(user_details){
          let track_user_id = 100000 + user_details.user_id;
          identifyUser(track_user_id);
        }

          console.log(user_details);
          }).catch(err=>{
            console.log("ërror here:", err); 
          })
      }
    }
    return (<UserContext.Provider value={[context, setContext]}>
        {children}
    </UserContext.Provider>)
}

