import React from "react";
// import background from './static/Background06.jpg';
import { Container } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { CookiesProvider } from "react-cookie";
import "./App.css";

import { UserContextProvider } from "context/UserContext";
import { Homepage } from "pages/Homepage";
import { Footer } from "components/Footer";
import { pageView } from "lib/analytics";
pageView('home');

function App() {
  document.title = 'SuperLayer Community App';

  return (
    <CookiesProvider>
      <div className="App">
        <header className="App-header">
        </header>
        <div className="Main-Body">
          <Container fluid>
            <UserContextProvider>
              <Homepage />
            </UserContextProvider>
          </Container>
        </div>
        <Container>
          <Footer />
        </Container>
      </div>
    </CookiesProvider>
  );
}

export default App;
