export const setUserDetails = (user_profile) => {
  const user_details = {
    user_id: user_profile.id,
    phone_verified: user_profile.phone_verified,
  };
  
  if (user_profile.wallet && user_profile.wallet) {
    user_details.wallet_address = user_profile.wallet.wallet_address;
    user_details.wallet_connected = true;
    user_details.wallet_name = user_profile.wallet.wallet_name;
  }
  if (user_profile?.claim?.signature) {
    user_details.sign_status = user_profile.claim.status;
    user_details.sign = user_profile.claim.signature;
    user_details.error_type = user_profile.claim.error_type;
    user_details.total_coins = user_profile.claim.total_coins;
  }

  if (user_profile.email){
    user_details.email= user_profile.email;
    user_details.email_verified= user_profile.email_verified;
  }
  if(user_profile?.twitter_clicked){
    user_details.twitter_clicked= user_profile.twitter_clicked;

  }
  if (user_profile?.twitter_username && user_profile?.retweet_id){
    user_details.twitter_username= user_profile.twitter_username;
    user_details.retweet_id= user_profile.retweet_id;
  }
  return user_details;
}

export const setApiErrors = (err, setErrors) => {
  if (err && err.response && err.response.data) {
    return setErrors(err.response.data);
  } else {
    return setErrors({
      err_code: "UNKNOWN",
      err_message: "Oops, something fishy is happening.",
    });
  }
};