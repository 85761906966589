import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import { FaAngleDown, FaAngleRight, FaCheck } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

export const Fold = ({
  title,
  step,
  validated,
  coins,
  isActive,
  children,
}) => {
  
  // function toggle() {
  //   isActive = true;
  //   setIsOpened((wasOpened) => !wasOpened)
  // }


  const renderCoins = (coins) => {
      if (coins)
        return (
          <Grid.Column width={2} textAlign="right" className="tokens">
            {validated && <FaCheck className="right-padding"/>}
          </Grid.Column>
        );
      else return <Grid.Column width={2} textAlign="right"></Grid.Column>;
  };

  const renderContent = () => {
    if (isActive)
      return (
        <Grid.Row>
          <Grid.Column>{children}</Grid.Column>
        </Grid.Row>
      );
  };

  return (
    <Segment className={`Main-Segment ${isActive?"active":""}`} >
      <Grid className="fold">
        <Grid.Row fluid className={`foldTitle ${validated?'validated':''}`}>
          <Grid.Column className={isActive?"":""} width={14} textAlign="left">
           <div>{step}. {title} <IconContext.Provider value={{className:"visibleIcon"}}>{isActive ? <FaAngleDown size={16}/> : <FaAngleRight size={16} /> }</IconContext.Provider></div>
          </Grid.Column>
          {renderCoins(coins)}
        </Grid.Row>

        {renderContent(step)}
      </Grid>
    </Segment>
  );
};
