import React, { useContext, useState, useMemo, useEffect } from "react";
import { WalletAdapterNetwork, WalletError } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
  useConnection,
  useWallet
} from "@solana/wallet-adapter-react";
import { getPhantomWallet, 
  // getSolflareWallet
 } from "@solana/wallet-adapter-wallets";
import {
  WalletModalProvider,
  WalletDisconnectButton,
  WalletMultiButton,
  WalletModalButton,
  WalletConnectButton
} from "@solana/wallet-adapter-react-ui";
import { clusterApiUrl } from "@solana/web3.js";
import { UserWallet } from "./UserWallet";

require("@solana/wallet-adapter-react-ui/styles.css");
export const Wallet = ({ children, ...props }) => {
  // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
  const network = WalletAdapterNetwork.Devnet;
  
  // You can also provide a custom RPC endpoint
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(() => [getPhantomWallet()], [network]);
  
  return (
      <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets}>
              <WalletModalProvider logo="superlayer_logo_white_blue.png" className="WalletProviderSection two ui buttons">
                 <UserWallet />   
              </WalletModalProvider>
          </WalletProvider>
      </ConnectionProvider>
  )
};
