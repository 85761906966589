

export const trackEvent = (event_name,data) => {
    window.analytics.track(event_name,data);
  };

export const identifyUser = (user_id, data) =>{
  window.analytics.identify(user_id, data);
  };

export const pageView = (name, data) =>{
    window.analytics.page(name, data);
    };
