import React, { useEffect } from "react";
import { Challenge } from "components/Challenge";
import { useState } from "react";
import { Container, Dimmer, Grid, Message, Segment } from "semantic-ui-react";
import { Wallet } from "./Wallet";
import { UserContext } from "../context/UserContext";
import logo from "../sl-green-white.svg";
import {Link, scroller} from "react-scroll";
import {RALLY_TOKEN_TEXT, WHAT_SUPERLAYER_TEXT, COMPLETE_STEP, MOBILE_DISABLED_MESSAGE, HEADER_TOP, DISCORD_LINK, WHAT_SUPERLAYER_COMM_TEXT, COMPLETE_STEP_TEXT, GIVEAWAY_TITLE} from "../lib/content";
import { useMediaQuery } from 'react-responsive'
import { trackEvent } from "lib/analytics";


export const Giveaway = ({ children, ...props }) => {
  const [user, setUser] = React.useContext(UserContext);
  const [challengeStatus, setChallengeStatus] = useState("INIT");
  const isMobile = useMediaQuery({ maxWidth: '800px' })
  // const isMobile = false

  useEffect(() => {
    console.log("Challenge",challengeStatus)
    if (user.phone_verified && user.email_verified && user.twitter_clicked) {
      setChallengeStatus("COMPLETE");
    }
    return () => {};
  }, [user.phone_verified, user.email_verified, user.twitter_clicked]);

  const renderMobileMessage = () => {
    return (<Message className="mobile" icon="computer" content={MOBILE_DISABLED_MESSAGE} size="medium">
        
    </Message>)
  }
  return (
    <>
    <div className="Giveaway-Grid">
      <Container>
      <Grid >
        <Grid.Row columns={1}>
          <Grid.Column>
            <img src={logo} className="App-logo" alt="logo" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column className="first-fold">
                  <h1 className="top-title">
                  {HEADER_TOP}
                  </h1>
                  {/* <h1 className="">😎</h1> */}

                  <div className="question-head">
                    WHAT IS SUPERLAYER
                  </div>
                  <div className="answer-head">
                    {WHAT_SUPERLAYER_TEXT.split('\n').map((text)=>{return <div>{text}</div>})}
                    <a href="https://superlayer.io" target="_blank" className="green-link" onClick={() => trackEvent('SUPERLAYER LINK CLICKED')}>Superlayer.io</a>
                  </div>

                  <div className="question-head">
                    <div >WHAT IS THE SUPERLAYER  COMMUNITY</div>
                  </div>
                  <div className="answer-head">
                    {WHAT_SUPERLAYER_COMM_TEXT.split('\n').map((text)=>{return <div>{text}</div>})}
                    <a target="_blank" href={DISCORD_LINK} className="green-link" onClick={() => trackEvent('DISCORD LINK CLICKED')}>
                    Join SuperLayer Discord
                  </a>
                  </div>
                  <div className="answer-head">
                  
                  </div>
                  <div className="question-head">
                    <div>{COMPLETE_STEP}</div>
                  </div>
                  <div className="answer-head">
                  </div>
                  <div className="answer-head">
                      {COMPLETE_STEP_TEXT} <span>Have questions? <a className="green-link" onClick={(e)=>{trackEvent('FAQ LINK CLICKED'); scroller.scrollTo('static-content',{duration:1000, delay: 100,smooth: true})}}> FAQ here </a></span>
                  </div>
                  <hr className="green"/>
                  {/* 🚀 ⌛*/}
                  </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={1} textAlign="center">
          <Grid.Column columns={1}>
            <Dimmer.Dimmable as={Segment.Group} dimmed={isMobile} className="giveaway-row Main-Group">
              <h2 className="top-title medium">{GIVEAWAY_TITLE}</h2>
              <Challenge disabled={isMobile}></Challenge>
              {!isMobile && <Wallet challengeStatus={true}></Wallet>}
              <Dimmer active={isMobile}>
                {/* <Header as="h2" icon inverted> */}
                {/* <Icon name="computer" /> */}
                {/* <FaBell /> */}
                {/* </Header> */}
                  
                {/* <div> */}
                {/* {MOBILE_DISABLED_MESSAGE} */}
                {/* </div> */}
          </Dimmer>
        </Dimmer.Dimmable>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
          {isMobile && renderMobileMessage()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      </Container>
      </div>
    </>
  );
};
