import React, { useContext, useState, useEffect } from "react";
import { Button, ButtonContent, Form, Message } from "semantic-ui-react";
import PhoneField from "react-phone-input-2";
import { isPossiblePhoneNumber } from "libphonenumber-js";

import { post } from "../apiRequests";
import { UserContext } from "../context/UserContext";
import "react-phone-input-2/lib/semantic-ui.css";
import { setApiErrors, setUserDetails } from "lib/utils";
import { identifyUser, trackEvent } from "lib/analytics";


/** Component to verify phone number.  */
const BUTTON_STATES = {
  ACTIVE: "active",
  loading: "loading",
  DISABLED: "disabled",
  hidden: "hidden",
};

const FORM_STATES = {
  PHONE: "phone",
  OTP: "otp",
  SUCCESS: "success",
  FAIL: "fail",
};


export const PhoneVerify = ({ children, ...props }) => {
  
  const [userContext, setUserContext, refreshUser] = useContext(UserContext);

  const NO_ERROR_STATE = { err_code: "", err_message: "" };
  const [errors, setErrors] = useState(NO_ERROR_STATE);
  const [state, setState] = useState({
    phone: userContext.phone,
    auth_code: "",
    otp: "",
    country_code: "us",
  });
  
  const [buttonState, setButtonState] = useState({
    generate: "active",
    validate: "hidden",
  });
  
  const [formState, setFormState] = useState(
    userContext.phone_verified ? FORM_STATES.SUCCESS : FORM_STATES.PHONE
  );



  const handleChange = (e, { name, value }) => {
    setState({ ...state, [name]: value });
  };

  const updatePhoneState = (val, country, e, formattedValue) => {
    setState({ ...state, phone: val, country_code: country.countryCode });
  };

  useEffect(() => {
    if (userContext.phone_verified) {
      setFormState(FORM_STATES.SUCCESS);
    }
    return () => {};
  }, [userContext]);


const generateOtp = async (phone) => {
  let response = await post("/user/phone/generate", { phone: phone }, {});
  const data = response.data;
  if (data && data.auth_code && data.user && data.user.id)
    return { auth_code: data.auth_code, user: data.user };
};

  const handleGenerate = async (e) => {
    e.preventDefault();
    setErrors(NO_ERROR_STATE);
    // Check if phone number is valid. Using the libgoogle library for now.
    // Module returns country names by lowercase - convert to uppercase for comparison
    if (!isPossiblePhoneNumber(state.phone, state.country_code.toUpperCase()))
      return setErrors({
        err_code: "FMV_PH",
        err_message: "Phone Number is invalid",
      });
    // Change Button state before making api call.
    setButtonState({ generate: "loading" });
    trackEvent('Generate OTP', {
      state:'INIT'
    });
    try {
      console.log("insidee");
      let { auth_code, user } = await generateOtp(
        state.phone.trim(),
        state.country_code.trim()
      );

      //Disable Button. Update Auth_code
      if (auth_code && user) {
        setButtonState({ generate: "hidden" });
        setFormState(FORM_STATES.OTP);
        setUserContext({
          ...userContext,
          user_id: user.id,
          phone: state.phone,
        });
        setState({ ...state, auth_code: auth_code });
        trackEvent('Generate OTP Success');

      } else {
        setErrors({ err_message: "Something went wrong with server reply." });
        trackEvent('Generate OTP Failed');
      }
      //TODO: Show message for retry. Add retry button text and click.
      return;
    } catch (err) {
      setButtonState({ generate: "active" });
      setApiErrors(err, setErrors);
      trackEvent('Generate OTP Failed', {
        error:err
      });
    }
  };

  const handleValidate = async (e) => {
    e.preventDefault();
    setErrors(NO_ERROR_STATE);

    setButtonState({ validate: "loading" });
    trackEvent('Verify User');

    try {
      if (!userContext || !userContext.user_id) {
        setFormState(FORM_STATES.PHONE);
        setButtonState({ generate: "active", validate: "hidden" });
        throw new Error("Start again");
      }

      let response = await post(
        "/user/phone/validate",
        { ...state, user_id: userContext.user_id },
        {}
      );
      let data = response.data;
      let user_profile = null;
      let solscan = null;
      let jwtToken = null;
      console.log(data);
      if (response?.data?.user)
        user_profile = response.data.user;
      if (response?.data?.token)
      {
        jwtToken = response.data.token;
      }
      else throw new Error("User not found");

      if(data && data.solscan)
        solscan = data.solscan;


      let user_details = setUserDetails(user_profile)
      localStorage.setItem('token', jwtToken);
      setUserContext({
        ...userContext,
        ...user_details
      });

      let track_user_id = 100000 + user_details.user_id;
      identifyUser(track_user_id);
      

      setButtonState({validate: "hidden" });
      setFormState(FORM_STATES.SUCCESS);
      trackEvent('Verify User Success');

    } catch (err) {
      console.log(err);
      setButtonState({ validate: "active" });
      setApiErrors(err, setErrors);
      trackEvent('Verify User Failed');

    }
  };

  const renderErrors = () => {
    return <Message error content={errors["err_message"]} />;
  };

  const renderButtons = () => {
    return<>
    {formState === FORM_STATES.PHONE &&
        <div className="ui buttons">
          <Button
            floated="left"
            className="ui button main large"
            type="submit"
            onClick={handleGenerate}
            loading={buttonState.generate === "loading"}
            disabled={
              buttonState.generate === "loading" ||
              buttonState.generate === "disabled"
            }
            active={buttonState.generate === "active"}
            tabindex={2}
          >
            <ButtonContent visible>Send Verification Code</ButtonContent>
          </Button>
        </div>
  } {
    formState === FORM_STATES.OTP &&
        <div className="ui buttons">
          <Button
            floated="right"
            className="ui button main large"
            type="submit"
            onClick={handleValidate}
            active={buttonState.validate === "active"}
            disabled={buttonState.validate === "disabled"}
            hidden={buttonState.validate === "hidden"}
            tabindex={4}
          >
            Verify
          </Button>
        </div>
  } {
        formState === FORM_STATES.SUCCESS &&
      <div className="success">Phone Verified</div>
    }
      </>
  };

  

  return (
    <Form className="PhoneForm" error={errors.err_message ? true : false}>
      <Form.Field>
        <PhoneField
          value={state.phone}
          onChange={updatePhoneState}
          inputProps={{ name: "phone"}}
          buttonClass="Phone-Field"
          inputClass="phoneDropDown"
          country={state.country_code}
          autoFormat={true}
          enableSearch
          onEnterKeyPress={handleGenerate}
          autocompleteSearch
          tabindex={1}
          disabled={formState === FORM_STATES.PHONE ? false : true}
          rules={{
            required: true,
            valiidate: (value) => isPossiblePhoneNumber(value),
          }}
        />
        {errors["phone-input"] && (
          <p className="error-message">Invalid Phone</p>
        )}
      </Form.Field>
      <Form.Field className={formState === FORM_STATES.OTP ? "otpformfield" : "hidden"}>
        <label>Enter the code sent to {state.phone}</label>
        <Form.Input
          // disabled={state.auth_code == ""}
          onChange={handleChange}
          onEnterKeyPress={handleValidate}
          name={"otp"}
          tabindex={3}
          autoFocus
          className={"otpField"}
          value={state.otp}
        ></Form.Input>
      </Form.Field>
      {renderErrors()}
      {renderButtons()}
    </Form>
  );
};
