import { DISCORD_LINK, TWITTER_PAGE, LINKEDIN_PAGE, TOC_LINK, PRIVACY_LINK } from "lib/content";
import React from "react";
import { FaDiscord, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Grid, Image } from "semantic-ui-react";
import logo from "../static/logo_rgbh.svg";


export const Footer = () => {
    return (<><Grid >
        <Grid.Row columns={2} className="footer-row" textAlign="center">
            <Grid.Column>
            {/* <a href="https://superlayer.io"> */}
            <Image src={logo} style={{height:"90%"}} fluid verticalAlign='middle' />
            {/* </a> */}
            </Grid.Column>
            <Grid.Column >
                <Grid.Row className="footer-after-logo" >
                <a target="_blank" href={TWITTER_PAGE}><FaTwitter></FaTwitter></a>
                <a target="_blank" href={DISCORD_LINK}><FaDiscord /></a>
                <a target="_blank" href={LINKEDIN_PAGE}><FaLinkedin /></a>
                </Grid.Row>
                <Grid.Row className="toc">
                <a target="_blank" href={PRIVACY_LINK}>Privacy Policy</a>
                <span className="horizontal marign"> | </span>
                <a target="_blank" href={TOC_LINK}>Terms and Conditions</a>
                </Grid.Row>
            </Grid.Column>
            </Grid.Row>
        </Grid></>)
};
