import React, { useRef, useState, useEffect } from "react";
import { Giveaway } from "components/Giveaway";
import { StaticContent } from "components/StaticContent";
import { GoToButton } from "components/GoToButton";

export const Homepage = () => {
  
  return (
    <div id="top">
      <GoToButton displayAfter={200} />
      <Giveaway />
      <StaticContent/>
      
    </div>
  );
};
