import React, { useState, useContext } from "react";
import { Tweet } from "react-twitter-widgets";

import { UserContext } from "../context/UserContext";
import { post } from "../apiRequests";
import { Config } from "../Config";
import {
  Button,
  Grid,
} from "semantic-ui-react";
import { FaTwitter } from "react-icons/fa";
import { trackEvent } from "lib/analytics";

export const Twitter = ({ children, ...props }) => {
  const tweet_id = Config.tweet_id;
  const [user, setUser] = useContext(UserContext);

  // const [retweetUrl, setRetweetUrl] = useState("");
  // const [retweetId, setRetweetId] = useState(user.retweet_id);

  // const updateRetweetUrl = (e, { name, value }) => {
  //   setRetweetUrl(value);
  // };

  const handleClicked = async (e) => {
    e.preventDefault();
    window.open("https://twitter.com/intent/retweet?tweet_id=1453791007779209222","_blank")
    try {
      const res = await post(
        "/user/social/twitter",
        { user_id: user.user_id },
        {}
      ).catch((err) => {
        console.log(err);
        return;
      });
      console.log(res.data);
      if (res && res.data && res.data.user) {
        console.log(res.data);
        setUser({
          ...user,
          twitter_clicked: res.data.user.twitter_clicked,
        });
        trackEvent('Twitter Clicked');

      }
    } catch (err) {}
  };

  // const handleVerify = async (e) => {
  //   // let tweet_id = retweetUrl.substring(retweetUrl.lastIndexOf('/') + 1);
  //   let tweet_id = retweetUrl.replace(/.*\/(\w+)\/?$/, "$1");

  //   let id = parseInt(tweet_id);
  //   if (id && !isNaN(id) && user.user_id) {
  //     const res = await post(
  //       "/user/social/twitter",
  //       { user_id: user.user_id, tweet_id: tweet_id },
  //       {}
  //     ).catch((err) => {
  //       console.log(err);
  //       return;
  //     });

  //     if (res && res.data) {
  //       console.log(res.data);
  //       setUser({
  //         ...user,
  //         twitter_username: res.data.twitter_username,
  //         retweet_id: res.data.retweet_id,
  //       });
  //       // setRetweetId(res.data.retweet_id);
  //     }
  //   }
  // };

  return (
    <>
      {" "}
      {!user.twitter_clicked && (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Tweet
                tweetId={tweet_id}
                options={{ cards: "hidden"}}
              />
              {/* <a className="twitter-share-button" href="https://twitter.com/intent/tweet" data-size="large" data-dnt="true" data-text="You should checkout " data-url="https://twitter.com/superlayerio/status/1480962733646307330" data-align="center">Retweet this</a> */}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button className="ui button main" onClick={handleClicked} data-size="large" data-dnt="true" data-text="You should checkout " data-url="https://twitter.com/superlayerio/status/1480962733646307330" data-align="center">
                <FaTwitter></FaTwitter> Retweet
              </Button>
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row>
            <Grid.Column width={4}>
              <Share url={"https://twitter.com/superlayerio/status/"+tweet_id} options={{text:"You should checkout ", size:"large", align:"center"}}></Share>
            </Grid.Column>
            <Grid.Column width={4}>
              <a className="twitter-share-button" href="https://twitter.com/intent/tweet" data-size="large" data-dnt="true" data-text="You should checkout " data-url="https://twitter.com/superlayerio/status/1480962733646307330">Retweet this</a>
              <Follow username="superlayerio" options={{size: "large", dnt: "true", align:"center" }} />
            </Grid.Column>
          </Grid.Row> */}
          {/* <Grid.Row>
            <Grid.Column className="white-color twitter-steps">

              <p><FaAngleRight /> Click on the Tweet above and follow the page</p>
              <p><FaAngleRight /> Retweet this tweet tagging one of your freind</p>
              <p><FaAngleRight /> Paste Retweet Link</p>
            Follow the SuperLayer Twitter page and Retweet this below Tweet tagging atleast one of your freind and paste the retweet link in below box. 
            </Grid.Column>
          </Grid.Row> */}
          {/* <Grid.Row>
            <Grid.Column>
              <Form>
                <label className="white-color grab-rally">
                  Enter Retweet Link
                </label>
                <Form.Field className="twitter">
                  <Form.Input
                    name="reetweet_url"
                    onChange={updateRetweetUrl}
                    className="inputFieldGreen"
                    value={retweetUrl}
                  ></Form.Input>
                  <Button className="button ui" onClick={handleVerify}>
                    <ButtonContent>Verify</ButtonContent>
                  </Button>
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row> */}
        </Grid>
      )}
    </>
  );
};
