import React, { useContext, useCallback, useState, useEffect } from "react";
import {
  WalletError,
  WalletConnectionError,
} from "@solana/wallet-adapter-base";
import {
  useWallet,
  WalletNotSelectedError,
} from "@solana/wallet-adapter-react";
import {
  WalletModalProvider,
  useWalletModal,
} from "@solana/wallet-adapter-react-ui";
import {
  Button,
  Checkbox,
  Container,
  Grid,
  Message,
  Popup,
  Segment,
} from "semantic-ui-react";
import { post } from "../apiRequests";
import { UserContext } from "../context/UserContext";
import ReactMarkdown from 'react-markdown';

import "@solana/wallet-adapter-react-ui/styles.css";
import {
  FaAngleDown,
  FaAngleRight,
  FaHourglassHalf,
  FaTimesCircle,
} from "react-icons/fa";
import { AIRDROP_ERROR, AIRDROP_SUCCESS, AIRDROP_UNKNOWN_ERROR, AIRDROP_WALLET_ERROR, DISCORD_LINK, MARKETING_OPT_IN, RALLY_TOKEN_TEXT, WALLET_CONNECT_ERROR } from "lib/content";
import { WalletFAQ } from "./WalletFAQ";
import { setApiErrors, setUserDetails } from "lib/utils";
import { trackEvent } from "lib/analytics";

const NO_ERROR_STATE = { err_code: "", err_message: "" };

export const UserWallet = ({ children, ...props }) => {
  const [walletStatus, setWalletStatus] = useState("DISABLED");
  const [user, setUser] = React.useContext(UserContext);
  const [challengeStatus, setChallengeStatus] = useState("PENDING");

  const [errors, setErrors] = useState(NO_ERROR_STATE);

  const { wallet, publicKey } = useWallet();

  const [walletSelected, setWalletSelected] = useState("");

  useEffect(() => {
    if (user.phone_verified && user.email_verified && user.twitter_clicked) {
      setWalletStatus("ACTIVE");
      setChallengeStatus("COMPLETE");
    }
    return () => {};
  }, [user.phone_verified, user.email_verified, user.twitter_clicked]);

  const updateWalletAddress = async () => {
    try {
      if (
        user?.wallet_address === "" &&
        user?.wallet_address !== publicKey?.toString() &&
        wallet &&
        publicKey &&
        user?.user_id
      ) {
        let response = await post(
          "/user/wallet/update",
          {
            user_id: user.user_id,
            walletAddress: publicKey.toString(),
            walletName: wallet.name,
          },
          {}
        );
        const data = response.data;
        if (data) {
          trackEvent('Wallet Connect Success');
          setUser({
            ...user,
            wallet_connected: true,
            wallet_address: publicKey.toString(),
            wallet_name: wallet.name,
          });
        }
          
      }
    } catch (err) {
      if(err?.response?.data)
      setErrors({err_message:err?.response?.data.err_message})
      console.log();
    }
  };

  // useEffect(() => {
  //   console.log("walletSelected", walletSelected);
  //   if (walletSelected) setWalletStatus("SELECTED");
  //   if (!walletSelected && walletStatus === "SELECTED")
  //     setWalletStatus("ACTIVE");
  //   return () => {};
  // }, [walletSelected, walletStatus, setWalletStatus]);

  const renderErrors = () => {
    return <Grid.Row ><Grid.Column><Message error content={errors["err_message"]} /></Grid.Column></Grid.Row>;
  };

  const getComponent = () => {
    // eslint-disable-next-line default-case
    if (challengeStatus === "PENDING") {
      return <WalletNotActive />;
    } else if (challengeStatus === "COMPLETE") {
      if (!user.wallet_address) {
        if (walletStatus === "ACTIVE")
          return <WalletSelectButton setWalletStatus={setWalletStatus} setErrors={setErrors} />;
        if (walletStatus === "SELECTED")
          return (
            <WalletConnectButton
              setWalletStatus={setWalletStatus}
              updateWalletAddress={updateWalletAddress}
              setErrors={setErrors}
            />
          );
        if (walletStatus === "CONNECTED")
          return <div>{user.wallet_address}</div>;
      }
      if (user.wallet_address) {
        if (!user.sign)
          return (
            <>
              <WalletAddress
                wallet_address={user.wallet_address}
              ></WalletAddress>
              <WalletClaimButton setErrors={setErrors} />
              
            </>
          );
        if (user.sign)
          return (
            <>
              <WalletAddress
                wallet_address={user.wallet_address}
              ></WalletAddress>
              <AirdropComplete sign={user.sign}></AirdropComplete>
            </>
          );
      }
    }
  };

  return (
    <>
      <Segment className="Main-Segment">
        <Grid className="wallet-content">
          <WalletModalProvider
            logo="superlayer_logo_white_blue.png"
            className="WalletProviderSection"
          >
            {getComponent()}
            {errors.err_message && renderErrors()}
          </WalletModalProvider>
        </Grid>
      </Segment>
    </>
  );
};

export const AirdropComplete = ({ sign }) => {
  const [copy, setCopied] = useState(false);

  return (
    <>
      <Grid.Row className="white-color clear-text" columns={1}>
        <Grid.Column className="">
          <a
            target="_blank"
            href={"https://solscan.io/tx/" + sign}
            className="airdropLink"
          >
            <span>solscan.io/tx/{sign?.slice(0, 15) + "...." + sign?.slice(-15)}</span>
          </a>
          {/* <Popup content={copy ? <span style={{color: 'red'}}>Copied.</span> : null}> */}
          {/* <CopyToClipboard onCopy={()=>{setCopied(true)}} text={sign}> */}
          
          {/* </CopyToClipboard> */}
          {/* </Popup> */}
          
          <ReactMarkdown>{AIRDROP_SUCCESS}</ReactMarkdown>
          {/* <div> Next airdrop is coming soon to not miss join the Rally Discord</div> */}
          
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="discordRow">
          <a
            target="_blank"
            href={DISCORD_LINK}
            className="button ui main large"
            onClick={() => trackEvent('DISCORD LINK CLICKED')}
          >
            Join SuperLayer Discord
          </a>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export const WalletAddress = ({ wallet_address }) => {
  return (
    <Grid.Row className="white-color">
      <Grid.Column>
        <h3 className="top-title small"> Connected Wallet</h3>
        <div className="small-text">{wallet_address}</div>
      </Grid.Column>
    </Grid.Row>
  );
};

export const WalletSelectButton = ({ disabled, setWalletStatus, setErrors }) => {
  const { wallet, connect, publicKey } = useWallet();

  const { visible, setVisible } = useWalletModal();

  const handleSelect = useCallback(() => {
    // e.preventDefault();
    setErrors(NO_ERROR_STATE);
    setVisible(!visible); 
    trackEvent('Wallet Select Modal Open');
  }, [visible, setVisible]);

  useEffect(() => {
    console.log("Wallet Selected:", wallet);
    if (wallet?.name) {
      setWalletStatus("SELECTED");
    }
    return () => {
      console.log("exit", wallet);
    };
  }, [wallet]);

  return (
    <Grid.Row>
      <Grid.Column className="ui buttons">
        <Button
          className="button ui main large"
          onClick={handleSelect}
          disabled={disabled}
        >
          Connect Wallet
        </Button>
      </Grid.Column>
    </Grid.Row>
  );
};

export const WalletNotActive = () => {
  return (
    <Grid.Row>
      <Grid.Column className="ui buttons">
        <Button className="button ui main large" disabled>
          Connect Wallet
        </Button>
      </Grid.Column>
    </Grid.Row>
  );
};
export const WalletConnectButton = ({
  setWalletStatus,
  updateWalletAddress,
  setErrors
}) => {
  const { wallet, connect, connected, publicKey, autoConnect, ready } = useWallet();

  useEffect(() => {
    console.log("Wallet Selected for connect: ",ready, wallet);

    try{
    if (!wallet) setWalletStatus("ACTIVE");
    else {
      if(wallet && ready) {
        console.log(wallet);
      trackEvent('Wallet Selected', {wallet_name: wallet.name});

        connect().catch(err=>{
          trackEvent('Wallet Connect Closed');
          
          if(err instanceof WalletConnectionError)
            setErrors({err_message:WALLET_CONNECT_ERROR})
            else
            console.log("errr:", err);
        });
      }
    }
  }catch(err){
    console.log("err",err.toString());
  }
    return () => {

    };
  }, [wallet, ready]);

  useEffect(() => {
    if (publicKey && connected) {
      trackEvent('Wallet Connect UI');
      updateWalletAddress();
    }
    if (publicKey) setWalletStatus("CONNECTED");
    return () => {};
  }, [publicKey, connected, setWalletStatus, updateWalletAddress]);

  const handleConnect = async (e) => {
    if (e) e.preventDefault();
    try {
      if (wallet?.name) {
        console.log("Try connect", wallet.name);
        //  await select(wallet.name);
        await connect();
      }
    } catch (err) {
      if (
        err instanceof WalletNotSelectedError ||
        err instanceof WalletConnectionError ||
        err instanceof WalletError
      ) {
        // console.log("Wallet not selected")
        //- Something went wrong here, request you to try again and grab your Rally coins
        // select(wallet.name);
      } else {
        console.log(err);
      }
    }
  };

  return (
    <Grid.Row>
      <Grid.Column className="ui buttons">
        <Button onClick={handleConnect} className="ui main large">
        <img src={wallet?.icon} style={{height:"1.6rem"}} /> <span style={{verticalAlign:"text-top"}}>Connect your {wallet?.name} {" "}</span>
        </Button>
      </Grid.Column>
    </Grid.Row>
  );
};

export const WalletClaimButton = ({ setErrors }) => {
  const [buttonState, setButtonState] = useState("ACTIVE");
  const [user, setUser] = useContext(UserContext);
  const [termCheckbox, setTermCheckbox] = useState(true);

  const handleClaim = async () => {
    try {
      trackEvent('CLAIM INIT');
      if (user.user_id && user.wallet_address) {
        setButtonState("LOADING");
        let response = await post(
          "/user/wallet/claim",
          { user_id: user.user_id, opt_in: termCheckbox },
          {}
        );
        let user_profile = response.data;
        let user_details = setUserDetails(user_profile)
        
        if(user_profile?.claim?.status === "ERROR"){
          if(user_profile?.claim?.error_type === "HOT_WALLET_BALANCE_EMPTY"){
            setErrors({err_message:AIRDROP_WALLET_ERROR})
            trackEvent('CLAIM FAILED BALANCE LOW');
          }
          else {
            setErrors({err_message:AIRDROP_UNKNOWN_ERROR})
            trackEvent('CLAIM FAILED');
            
          }
        }
        setButtonState("DISABLED");
      
      if (user_profile?.claim?.signature){
        trackEvent('CLAIM SUCCESS', {sign: user_profile.claim.signature});

        setUser({
          ...user_profile,
          ...user_details
        });
      }
    }

    } catch (err) {
      setButtonState("ACTIVE");
      console.log(err);
    }
  };

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          {buttonState === "LOADING" && (
            <div>
              Processing your request... <FaHourglassHalf />
            </div>
          )}
          {buttonState === "ACTIVE" && (
            <>
              <Grid.Row><Button
                className={`ui button main large ${
                  buttonState === "LOADING" ? "hidden" : ""
                }`}
                name="claim"
                disabled={buttonState !== "ACTIVE" || termCheckbox === false}
                onClick={handleClaim}
              >
                Claim Tokens
              </Button>
              </Grid.Row><Grid.Row>
              <Checkbox
                label={MARKETING_OPT_IN}
                checked={termCheckbox}
                onChange={() => {
                  setTermCheckbox(!termCheckbox);
                }}
              ></Checkbox></Grid.Row>
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </>
  );
};
