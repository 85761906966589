import React, { useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { PhoneVerify } from "./PhoneVerify";
import { EmailConnect } from "./EmailConnect";

import { Fold } from "../components/Fold";
import { Twitter } from "./Twitter";

export const Challenge = ({children, ...props}) => {
  const [user, setUser] = React.useContext(UserContext);
  const [currentStep, setCurrentStep] = useState(user.current_step);
  const {disabled} = {...props};
  useEffect(() => {
    if (user.phone_verified) {
      setCurrentStep(2);

      if (user.phone_verified && user.email_verified) {
        setCurrentStep(3);
      }

      if (user.phone_verified && user.email_verified && user.twitter_clicked) {
        setCurrentStep(4);
      }
    }

    return () => {};
  }, [user.email_verified, user.phone_verified, user.twitter_clicked]);

  return (
    <>
      <Fold
        step={1}
        className="Main-Segment Phone-Verify"
        title={"Verify your phone number"}
        validated={user.phone_verified === true}
        isActive={!disabled && currentStep === 1}
        coins={10}
      >
        <PhoneVerify />
      </Fold>
      <Fold
        step={2}
        className="Main-Segment"
        title={"Connect your Gmail"}
        validated={user.email_verified === true}
        isActive={!disabled &&currentStep === 2}
        coins={20}
      >
        <EmailConnect />
      </Fold>
      <Fold
        step={3}
        className="Main-Segment"
        title={"Retweet the Linked Tweet"}
        validated={user.twitter_clicked}
        isActive={!disabled && currentStep === 3}
        coins={30}
      >
        <Twitter />
      </Fold>
    </>
  );
};

// const getComponent = () => {
//   switch(currentStep) {
//     case 1 :
//       return <PhoneVerify />
//     case 2 :
//       return <EmailConnect />
//     case 4 :
//       return <Wallet />
//     default :
//       return null
//   }
// }
