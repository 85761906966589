
import React, { useState, useEffect } from "react";
import {Button} from "semantic-ui-react"
import { Link, animateScroll as scroll } from "react-scroll";
import { FaAngleDoubleUp, FaAngleUp } from "react-icons/fa";

export const GoToButton = ({ displayAfter, target }) => {
  const [showButton, setShowButton] = useState(false);
  const handleShowButton = () => {
    if (!showButton && window.scrollY > displayAfter) {
      setShowButton(true);
      return;
    }
    if (!showButton && window.scrollY <= displayAfter) {
      setShowButton(false);
      return;
    }
  };

  window.addEventListener("scroll", handleShowButton);

  useEffect(() => {
    return window.removeEventListener("scroll", handleShowButton);
  });

  const scrollToTop = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
  };
  if(showButton)
  return (<Button href="top" className="totop button ui green inverted circular icon" onClick={scrollToTop}>
      <FaAngleUp size={24}/>
    </Button>)
    else return ""
};

