export const FAQ = [
    {
      question: "What is SuperLayer?",
      answer: `[SuperLayer](http://www.superlayer.io) is a community of builders creating cutting-edge consumer crypto products.  SuperLayer is a partner of the [RLY Network](https://rly.network/), an open, decentralized network powered by its governance token, RLY.`,
    },{
    question: "What is the SuperLayer Community?",
    answer: "The SuperLayer Community is a social club for web3 builders and early adopters interested in participating in SuperLayer projects and the broader RLY ecosystem. We’re big believers in user-driven networks and want to create a space for others who share this sentiment to join us.",
  },
  {
    question: "Why should I join the SuperLayer Community?",
    answer: `SuperLayer Community members will get early access to new SuperLayer projects, priority on future token/NFT drops, invitations to VIP events and 50 sRLY just for signing up.`,
  },
  {
    question:
      "How can I join the SuperLayer Community?",
    answer: `To keep the SuperLayer community strong, we have to verify new joiners to ensure they are real people. You can verify yourself by completing 3 steps: 
    Verify your phone number using a one-time verification code. 
    Confirm your email address by connecting your Gmail.
    Verify your Twitter account by retweeting the linked tweet.
    Once verified, you will be on the SuperLayer contact list for community announcements and can join fellow community members in the [RLY Ecosystem Discord](https://discord.com/invite/rlynetwork). `,
  },
  {
    question: "Do I need to complete all three steps to receive the 50 sRLY reward?",
    answer: `Yes. Once you complete the 3 steps, you can connect your Solana wallet (e.g., Phantom) and receive your sRLY. `,
  },
  {
    question: "What is sRLY?",
    answer: `[RLY](https://coinmarketcap.com/currencies/rally/) initially launched on Ethereum as an ERC-20 token and is currently listed on Coinbase and other major exchanges. sRLY is the SPL (Solana Program Library) version of the RLY token.`,
  },
  {
    question: "How do I hold sRLY?",
    answer: `sRLY is a Solana token. To hold it, you will need a Solana-compatible cryptocurrency wallet like [Phantom](https://phantom.app/).`,
  },
  {
    question: "Why is Superlayer giving sRLY to its Community?",
    answer: `[The RLY Association](http://www.rly.network) funds multiple projects within its ecosystem, including SuperLayer. SuperLayer and its projects leverage the RLY protocol and receive network rewards from the Association to distribute to its community members. `,
  },
  {
    question: "How is my personal information stored and used?",
    answer: `Your information is stored with the highest level of encryption and won’t be used for any purpose other than communicating community updates to members, and as otherwise outlined in our [Privacy Policy](https://legal.community.superlayer.io#h.3nksq758bcbk)`,
  },
  {
    question: "How can I talk to someone at SuperLayer? ",
    answer: `You can get in touch with the SuperLayer team via the #SuperLayer channel in the [RLY Ecosystem Discord](https://discord.com/invite/rlynetwork) `,
  },
];

export const RALLY_TOKEN_TEXT = "Rally (Solana)";
export const HEADER_TOP =
  "Join the SuperLayer Community, get 50 sRLY tokens, and help shape the future of the internet! ";
// export const HEADER_TOP = "Become a SuperLayer Community Member and Earn 50";

// export const WHAT_SUPERLAYER_TEXT = " SuperLayer is a community of builders creating cutting edge products in consumer crypto. \n By joining this community you: \n💰 Instantly receive 50 sRLY tokens \n⏱ Get early access to new products \n🎨 Receive NFTs and token rewards \n⛷ Help decide what we should build together next \n SuperLayer is part of the RLY Network";
export const WHAT_SUPERLAYER_TEXT =
  "SuperLayer is a web3 venture studio focused on building the future of consumer crypto. In partnership with the RLY ecosystem, we are a community of builders rapidly bringing to market a multitude of cutting edge tokenized products.";
export const WHAT_SUPERLAYER_COMM_TEXT =
  "The SuperLayer Community is a social club for web3 enthusiasts, builders, and early adopters interested in interacting more directly with SuperLayer projects and the broader RLY ecosystem.";
export const DISCORD_LINK = "https://discord.com/invite/rlynetwork";

// export const COMPLETE_STEP = "Complete the following three steps and connect your SPL wallet to earn 50 sRLY to use in future SuperLayer apps and products. "
export const COMPLETE_STEP = "JOIN THE SUPERLAYER COMMUNITY";
export const COMPLETE_STEP_TEXT =
  "To join the SuperLayer community, you'll first have to prove that you are a real person by verifying your e-mail, phone number and Twitter account. You can then connect your Solana wallet and claim your sRLY Tokens.";
export const MARKETING_OPT_IN =
  "By claiming this reward, you agree to receive SuperLayer community updates about new project launches, reward programs and NFT drops";
export const MOBILE_DISABLED_MESSAGE =
  "This application is currently not supported on mobile phones. Please use your desktop browser to sign up for the SuperLayer community and claim your sRLY tokens.";

export const GIVEAWAY_TITLE = "3 simple steps to get tokens:";

export const WALLET_CONNECT_ERROR = "Please try connecting the wallet again.";

export const AIRDROP_LOADING =
  "Processing transaction on the Solana blockchain.";
export const AIRDROP_WALLET_ERROR =
  "Your transaction is being processed and your sRLY will be transferred to you within within 48 hours.";
export const AIRDROP_SUCCESS =
`Reward claimed.  
Join the RLY Network Discord to ensure you are the first to know about new project launches, reward programs and NFT drops.`;
export const AIRDROP_UNKNOWN_ERROR =
"Your transaction is being processed and your sRLY will be transferred to you within within 48 hours.";

export const TWITTER_PAGE = "https://twitter.com/superlayerio";
export const LINKEDIN_PAGE = "https://www.linkedin.com/company/superlayer";

export const PRIVACY_LINK = "https://legal.community.superlayer.io#h.3nksq758bcbk";
export const TOC_LINK = "https://legal.community.superlayer.io";
