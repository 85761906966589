import React, {useState } from "react";
import {
  Container,
  Card,
  Grid,
  Header,
  HeaderSubheader,
  Image,
  Accordion,
  Icon,
} from "semantic-ui-react";
import Scroll from "react-scroll";
import ReactMarkdown from 'react-markdown';

import {FAQ} from "../lib/content";
import { trackEvent } from "lib/analytics";


const image_mahesh =
  "https://uploads-ssl.webflow.com/615698a3a04b3143b10d55b7/61569a5aabb506632fb25a43_mahesh.png";
const image_kevin =
  "https://uploads-ssl.webflow.com/615698a3a04b3143b10d55b7/61569988d89baf62d4076378_kevin.png";
  

  const Element  = Scroll.Element;
  const scroller = Scroll.scroller;


  export const StaticContent = () => {

  return (
    <>
    <Element name="static-content" >
      {/* <SLCommLaunch /> */}
      {/* <SLCommProjects /> */}
      {/* <SuperLayerTeam /> */}
      <SuperLayerFaq />
      </Element>
    </>
  );
};

const SLCommLaunch = () => {
  return (
    <>
      <div id="sl-comm-launch" className="superlayer-launch dark-background">
        <Container>
          <Grid className="launching-grid" centered textAlign="center">
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Header style={{color:'white'}} size="huge" className="heading">Launching SuperLayer Community</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <h2>What is SuperLayer?</h2>
                <h3 className="heading">
                  SuperLayer is a venture studio, building a community creating
                  transformative web3 experiences that democratize opportunity
                  and ownership of the internet.
                </h3>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="launching-background"></Grid.Row>
          </Grid>
        </Container>
      </div>
    </>
  );
};

const ProjectDescription = ({ children, ...props }) => {
  return (
    <Card>
      {/* style={{'background-color':'transparent', 'border':'0px'}} */}
      <Image></Image>
      <Card.Content>
        {/* <Image src="https://react.semantic-ui.com/images/avatar/large/matthew.png" /> */}
        <Card.Header>{props.name}</Card.Header>
        <Card.Meta>{props.release}</Card.Meta>
        <Card.Description className="project-desc">{children}</Card.Description>
        </Card.Content>
      </Card>
  );
};

const SLCommProjects = () => {
  return (
    <div id="sl-projects" className="light-background">
      <Container>
          <Header size="huge" textAlign="center" className="heading dark-color">
            What are the upcoming projects?
          </Header>
        <Card.Group className="projects-grid" doubling itemsPerRow={2}>
            <ProjectDescription name="Social content platform with user coins " release="March 2022">
            </ProjectDescription>
            <ProjectDescription name="Daily Fantasy Contest using athlete tokens  " release="March 2022">
            </ProjectDescription>
            <ProjectDescription name="Commerce on chain for Shops" release="March 2022">
            </ProjectDescription>
        </Card.Group>
      </Container>
    </div>
  );
};

const SuperLayerTeam = () => {
  const investorCount = investors.length;
  const listInvestors = (investors) => { return investors.map((investor)=><div key={`investors_`+investor}>{investor}</div>)}
  return (
    <>
      <div id="sl-team" className="superlayer-team dark-background">
        <Container>
          <Header size="huge" textAlign="center" style={{ color: "white" }}>
            Meet the SuperLayer Team
          </Header>
          <Header textAlign="center" style={{ color: "white" }}>
            <Grid columns={2} centered>
              <Grid.Row>
                <Grid.Column textAlign="right">
                  <Image
                    avatar
                    bordered
                    size="large"
                    circular
                    src={image_kevin}
                  ></Image>
                  <div>Kevin Chou</div>
                  <HeaderSubheader style={{ color: "white" }}>
                    Managing Partner
                  </HeaderSubheader>
                </Grid.Column>
                <Grid.Column>
                  <Image
                    avatar
                    bordered
                    centered
                    size="large"
                    circular
                    src={image_mahesh}
                  ></Image>
                  <div>Mahesh Vellanki</div>
                  <HeaderSubheader style={{ color: "white" }}>
                    Managing Partner
                  </HeaderSubheader>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Header>
          <div className="blank-space"></div>
          <Header
            size="huge"
            textAlign="center"
            style={{ color: "white" }}
          >
            Meet the community and investor
          </Header>
          <Grid doubling columns={4} className="investors" textAlign="center">
            <Grid.Row>
              <Grid.Column>{listInvestors(investors.slice(0,investorCount/4))}</Grid.Column>
              <Grid.Column>{listInvestors(investors.slice(investorCount/4,2*investorCount/4))}</Grid.Column>
              <Grid.Column>{listInvestors(investors.slice(2*investorCount/4,3*investorCount/4))}</Grid.Column>
              <Grid.Column>{listInvestors(investors.slice(3*investorCount/4,investorCount))}</Grid.Column>                
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    </>
  );
};

const investors = ["Aaron Schwartz","Albert Chen","Alex Bard","Anthony Saleh","Ben Metcalfe","Ben Parr","Bill Lee","Binh Tran","Brian Lee","Brit Morin","Carter Reum","Chris Dixon","Christa Stout","Christopher Heymann","Clint Kisker","Dan Schawbel","Daniel Rumennik","Dennis Fong","Ed Lu","Evan Cheng","Gary Vaynerchuk","Holly Liu","Jameson Hsu","Jeff Clavier","Jeff Morris Jr.","Jehan Chu","Jeremiah Owyang","Joe Montana","Joe Pulizzi","John Chu","Josh Dembling","Josh Fraser","Julianna Lamb","Keisuke Honda","Ken Chan","Kevin Kelly","Kun Gao","Laura Ventura","Lenny Ratchitsky","Maha Ibrahim","Marc Andreessen","Matt Liu","Michael Gentile","Michael Ovitz","Miguel Vias","Modernist","Nas","Nicholas Tuosto","Packy McCormick","Paris Hilton","Peter Flynn","Peter Hollens","Phil Yeh","Phillip Hyun","Raghu Yarlagadda","Reed McGinley-Stempel","Richard Ma","Roger Lee","Ronen Kirsh","Ruzwana Bashir","Sahil Bloom","Scott Hansen","Sheila Marcelo","Siqi Chen","Sriram Krishnan","Steve Chen","Sue Young"];


const SuperLayerFaq = () => {
  const [activeState, setActiveState] = useState(-1);

  const faq = FAQ.map((value, key)=>{
    return {
      key:'faq_'+key,
      title:value.question,
      content: value.answer
    }
  })
  const listFaq = () => { 
    return faq.map((f, key)=>
       <Grid.Row >
        <Grid.Column>
            <Accordion.Title key={f.key} 
            active={activeState === f.key}
          index={f.key}
          className="faq-question ui top-title medium"
          onClick={(e, props)=>{
            if(activeState === f.key) {setActiveState("-1");}
            else {
              trackEvent("FAQ clicked",{
                title: f.title
              })
              setActiveState(f.key);
            }
            }}>
            {f.title}
            <Icon name='dropdown' />
          </Accordion.Title>
            <Accordion.Content className="faq-answer ui" active={activeState === f.key}>
            <div><ReactMarkdown>{f.content}</ReactMarkdown></div>
              </Accordion.Content>
        </Grid.Column>
        </Grid.Row>
    )
    
  }
  
  return (
    <>
      <div id="sl-faq" className="superlayer-faq light-background">
        <Container>
          <div className="top-title medium">
            SuperLayer Community FAQ
          </div>
          <div className="blank-space"></div>
          <Grid>
        <Accordion fluid className="faq">  
            {listFaq()}
            </Accordion>

          </Grid>
        </Container>
      </div>
    </>
  );
};

